import homepageOverivew from "../assets/welcome/welcomeOverview.png";
import homepageArmor from "../assets/welcome/welcomeArmor.png";
import homepageMods from "../assets/welcome/welcomeMods.png";
import homepageStory from "../assets/welcome/welcomeStory.png";
import homepageUpgrades from "../assets/welcome/welcomeUpgrades.png";
import homepageWeapons from "../assets/welcome/welcomeWeapons.png";
import Img3 from "../assets/welcome3.png";

import Jul from "../assets/team/jul.jpg";
import Rahul from "../assets/team/rahul.jpg";
import Jen from "../assets/team/jen.jpg";
import Bun from "../assets/team/bun.jpg";
import Alex from "../assets/team/alex.jpg";
import Charlie from "../assets/team/charlie.jpg";
import Isabelle from "../assets/team/isabelle.jpg";

import Bleats2k10 from "../assets/testers/Bleats2k10.png";
import Chillbytes from "../assets/testers/Chillbytes.jpg";
import Gheiste from "../assets/testers/Gheiste.jpg";
import Pizzadave from "../assets/testers/Pizzadave.jpg";
import RosieRiots from "../assets/testers/RosieRiots.jpg";
import ItsQuipz from "../assets/testers/itsQuipz.jpg";

import KarateKid from "../assets/testers/KarateKid.jpeg";
import Nezuyaki from "../assets/testers/Nezuyaki.JPG";
import SirPugs from "../assets/testers/Sirpugs.jpg";
import AWildSasa from "../assets/testers/AWildSasa.jpeg";
import Ace from "../assets/testers/Ace.jpg";
import MatthiasRex from "../assets/testers/MatthiasRex.jpg";

export const HomepageData = [
    {
        key: "home1",
        type: "basic",
        title: "Overview",
        text: "Mirastell is a 2D Action-RPG with a unique flavor of turn-based combat, set on an abandoned space cruiser. \n\n Mirastell starts you off with no memories of your past, no equipment, and no allies. It’s up to you to explore the ship, learn about its history, discover the fate of the crew, and eventually escape. \n\n The ship’s passengers and crew may be missing, but you will quickly realize that you are not alone. Also onboard is The Arcadia, a mysterious and hostile AI that is constantly present throughout the game. The Arcadia is always watching, and always trying to stop you. \n\n To help you in your struggle against the Arcadia, Mirastell contains a wealth of equipment for you to unlock, including weapons, armor, and combat mods. These tools create a deep sense of progression and allow for a wide variety of combat styles.",
        src: homepageOverivew,
        reverse: false
    },
    {
        key: "home2",
        type: "basic",
        title: "Gameplay - Weapons",
        text: "Mirastell has a large collection of weapons to choose from, each with its own unique feel. They can be unlocked through a variety of methods, including crafting, completing quests, or discovering them in the world.",
        src: homepageWeapons,
        reverse: true
    },
    {
        key: "home3",
        type: "basic",
        title: "Gameplay - Upgrades",
        text: "Some weapons can be upgraded to gain additional perks. These upgrades are obtained in the same way as normal weapons and are meant as a way to ensure that you are able to invest time into your favorite equipment, and perfect your loadout.",
        src: homepageUpgrades,
        reverse: false
    },
    {
        key: "home4",
        type: "basic",
        title: "Gameplay - Armor",
        text: "Armor can be collected in similar ways to weapons and upgrades, and provides perks or stat-boosts that help you during combat.",
        src: homepageArmor,
        reverse: true
    },
    {
        key: "home5",
        type: "basic",
        title: "Gameplay - Mods",
        text: "Combat mods are craftable items that improve your performance during combat. These mods come in 4 tiers, and you can have 1 mod of each tier equipped at a time.\n \n During combat, defeating an enemy will give you 1 point of energy, activating your tier 1 combat mods. Defeating more enemies will activate more mods, powering you up as you fight the Arcadia.\n \n Mods are built to work well with other specific mods, weapons, and armor pieces. Carefully combining these tools will allow you to become extremely powerful.",
        src: homepageMods,
        reverse: false
    },
    {
        key: "home6",
        type: "basic",
        title: "World and Story",
        text: "Mirastell’s story is shrouded in mystery. Over the course of three years, this narrative has been developed into a dense web which you will slowly unravel as you play. Our goal is to provide a simple, and satisfying plot that can be understood by anyone, and a deeper story for those who are more interested in digging into the story. The game will even have multiple endings, depending on the decisions you make along the way. \n \n The Arcadia is an important part of the game’s story, and its relationship with the player develops over the course of the game. The Arcadia is present at all times, constantly taunting the player, and impeding their success. ",
        src: homepageStory,
        reverse: false
    },
    
];

export const TeampageData = {
    teamPageAbout:{
        key: "home1",
        type: "text",
        title: "About Mirastell",
        text: "Mirastell is made by a small group of indie developers, college students and fresh college graduates alike, who are passionate about the story they have to tell and the world they are telling it in. The team has worked together closely on every aspect of Mirastell to create an experience that is intriguing and beautiful with its own unique flair and style. Each member of the Mirastell team is an important piece, without whom this game and the experience it gives would not be possible.",
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/mirastellGame"},
            {type:"instagram", link:"https://www.instagram.com/mirastellgame/"},
            {type:"facebook", link:"https://www.facebook.com/MirastellGame/"},
            {type:"linkedin", link:"https://www.linkedin.com/company/trexus183"}
        ]
    }
};

export const TeamData = [
    {
        key: "team1",
        type: "profile",
        title: "Julius Condemi",
        subtitle: "Co-Owner",
        text: "Julius has been designing/developing games for over 6 years! He's an avid gamer whose commited 100s hours into games like: Destiny, Titanfall, & Fallout. While working, he's always jamming out to rock. If ya ever invite him over for movie night, know his favorite movie hands down is Birdman.",
        src: Jul,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/Trexus183"},
            {type:"instagram", link:"https://www.instagram.com/Trexus183/"},
            {type:"linkedin", link:"https://www.linkedin.com/in/julius-condemi-111a31159"},
            {type:"website", link:"https://www.trexus183.com/"}
        ],
        reverse: false
    },
    {
        key: "team2",
        type: "profile",
        title: "Rahul Sondhi",
        subtitle: "Co-Owner",
        text: "Rahul is an MBA grad student at Stony Brook University, with a BS in Computer Science. Back in the day he managed several school organizations, such as the computing society, and ran several events, including a hackathon he founded. Now he spends his day on Mirastell and his nights streaming on Twitch. He hates going to bed and hates getting up. His best friend is a cup of coffee loaded with sugar.",
        src: Rahul,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/hungryhul"},
            {type:"instagram", link:"https://www.instagram.com/hungryhul/"},
            {type:"linkedin", link:"https://www.linkedin.com/in/rahul-sondhi"},
            {type:"linktree", link:"https://linktr.ee/HungryHooligan"}
        ],
        reverse: true
    },
    {
        key: "team3",
        type: "profile",
        title: "Alexander Bakirdan",
        subtitle: "Social Media Manager",
        text: "Alex is a writer with a passion for fantasy and science-fiction. He spends most of his time glued to his desk writing, but when he’s not he enjoys playing games as well, especially Nintendo titles and strategy games. He’s also a huge fan of the outdoors and likes to hike and practice nature photography.",
        src: Alex,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/alexbakirdan"},
            {type:"instagram", link:"https://www.instagram.com/bakirdan55/"}
        ],
        reverse: false
    },
    {
        key: "team4",
        type: "profile",
        title: "Jen Bradley",
        subtitle: "Digital Artist",
        text: "Jen is a student studying her way to become a wildlife biologist (her love for animals is her main fuel source). When not hitting the books or drawing, she plays on her school's TESPA Overwatch League team as a support main. Her future dreams always include her driving her dream Tesla.",
        src: Jen,
        socialMedia: [
            {type:"instagram", link:"https://www.instagram.com/inquisitiveaardvark/"}
        ],
        reverse: true
    },
    {
        key: "team5",
        type: "profile",
        title: "Charlie Diaz",
        subtitle: "Internal QA",
        text: "Charlie is a computer science major and a streamer on twitch at gryffinboredd. The three things he loves most in the world are Kiley(His GF), Pandora (their mischievous cat) and Liverpool F.C. His favorite game series is hands down Assassin’s Creed and he says he sometimes completes living in a cabin in the woods, just as long as he could still have video games!",
        src: Charlie,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/gryffinboredd"},
            {type:"instagram", link:"https://www.instagram.com/gryffinboredd_/"},
            {type:"twitch", link:"https://www.twitch.tv/gryffinboredd"}
        ],
        reverse: false
    },
    {
        key: "team7",
        type: "profile",
        title: "Isabelle Greenberg",
        subtitle: "Voice Actor of Alana",
        text: "Isabelle is a Senior at Stony Brook University, where she is completing a Computer Science major with a Theatre minor. Outside of her coursework, she is the President of Stony Brook Computing Society and also performs in various campus plays. In her free time, she enjoys swimming and reading comics.",
        src: Isabelle,
        socialMedia: [
            {type:"linkedin", link:"https://www.linkedin.com/in/isabelle-greenberg"},
            {type:"instagram", link:"https://www.instagram.com/rings.an.isabelle/"},
            {type:"twitter", link:"https://twitter.com/rings_a_belle"}
        ],
        reverse: true
    }
];

export const AlphaData = [
    {
        key: "atest1",
        type: "card",
        title: "bleats2k10",
        subtitle: "Twitch Streamer",
        src: Bleats2k10,
        socialMedia: [
            {type:"twitch", link:"https://www.twitch.tv/bleats2k10"},
            {type:"instagram", link:"https://www.instagram.com/bleats2k10_vk/"},
        ],
        reverse: false
    },
    {
        key: "atest2",
        type: "card",
        title: "Gheiste",
        subtitle: "Twitch Streamer",
        text: "",
        src: Gheiste,
        socialMedia: [
            {type:"twitter", link:"https://www.twitter.com/gheiste "},
            {type:"instagram", link:"https://www.instagram.com/gheistespiele/"},
            {type:"facebook", link:"https://www.facebook.com/Gheiste-521476478318780"}
        ],
        reverse: true
    }
    ,
    {
        key: "atest3",
        type: "card",
        title: "Chillbytes",
        subtitle: "Twitch Streamer",
        text: "",
        src: Chillbytes,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/chillbytes"},
            {type:"facebook", link:"https://www.facebook.com/chillbytes"},
            {type:"website", link:"http://smughaven.com"}
        ],
        reverse: true
    },
    {
        key: "atest4",
        type: "card",
        title: "RosieRiots",
        subtitle: "Twitch Streamer",
        text: "",
        src: RosieRiots,
        socialMedia: [
            {type:"instagram", link:"https://www.instagram.com/rosieriotsttv/"},
            {type:"twitter", link:"https://twitter.com/RosieRiotsTTV"},
            {type:"twitch", link:"https://www.twitch.tv/rosieriots"}
        ],
        reverse: true
    },
    {
        key: "atest5",
        type: "card",
        title: "Pizza Dave",
        subtitle: "Twitch Streamer",
        text: "",
        src: Pizzadave,
        socialMedia: [
            {type:"twitch", link:"https://twitch.tv/1pizzadave "},
            {type:"twitter", link:"https://twitter.com/Rateddg3"}
        ],
        reverse: true
    },
    {
        key: "atest6",
        type: "card",
        title: "itsQuipz",
        subtitle: "Twitch Streamer",
        text: "",
        src: ItsQuipz,
        socialMedia: [
            {type:"twitch", link:"https://www.twitch.tv/itsquipz"},
            {type:"twitter", link:"https://twitter.com/ItsQuipz"},
            {type:"instagram",link:"https://www.instagram.com/itsquipz/"}
        ],
        reverse: true
    },
        {
        key: "atest7",
        type: "card",
        title: "Alana Sun",
        subtitle: "Twitch Streamer",
        text: "",
        src: Bun,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/ribunbun"},
            {type:"instagram", link:"https://www.instagram.com/ribunbun/"},
            {type:"linktree", link:"https://linktr.ee/ribunbun"},
            {type:"twitch", link:"https://www.twitch.tv/ribunbun"}
        ],
        reverse: true
    },
];

export const BetaData = [
    {
        key: "btest1",
        type: "card",
        title: "KarateKid",
        subtitle: "Twitch Streamer",
        text: "",
        src: KarateKid,
        socialMedia: [
            {type:"twitch", link:"https://www.twitch.tv/karatekid12549"},
            {type:"instagram", link:"https://www.instagram.com/matthewm12549/"}
        ],
        reverse: true
    },
    {
        key: "btest2",
        type: "card",
        title: "Nezuyaki",
        subtitle: "Twitch Streamer",
        text: "",
        src: Nezuyaki,
        socialMedia: [
            {type:"linktree", link:"https://linktr.ee/Nezuyaki"}
        ],
        reverse: true
    },
    {
        key: "btest3",
        type: "card",
        title: "SirPugs",
        subtitle: "Twitch Streamer",
        text: "",
        src: SirPugs,
        socialMedia: [
            {type:"twitch", link:"https://twitch.tv/sirpugs"},
            {type:"instagram", link:"https://www.instagram.com/pgscosplay"}
        ],
        reverse: true
    },
    {
        key: "btest4",
        type: "card",
        title: "awildsasa",
        subtitle: "Twitch Streamer",
        text: "",
        src: AWildSasa,
        socialMedia: [
            {type:"linktree", link:"https://linktr.ee/a.wild.sasa"}
        ],
        reverse: true
    },
    {
        key: "btest5",
        type: "card",
        title: "AcediaLecheryHIH",
        subtitle: "Twitch Streamer",
        text: "",
        src: Ace,
        socialMedia: [
            {type:"facebook", link:"https://www.facebook.com/acedia.lechery"},
            {type:"twitter", link:"https://twitter.com/AcediaLechery"},
            {type:"twitch", link:"https://www.twitch.tv/acedialecheryhih"}
        ],
        reverse: true
    },
    {
        key: "btest6",
        type: "card",
        title: "MatthiasRex",
        subtitle: "Twitch Streamer",
        text: "",
        src: MatthiasRex,
        socialMedia: [
            {type:"twitter", link:"https://twitter.com/MattTheHumanBoy"},
            {type:"twitch", link:"https://www.twitch.tv/matthiasrex"},
            {type:"instagram", link:"https://www.instagram.com/matthias.gif/"}
        ],
        reverse: true
    }
];

export default HomepageData;
