import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown'

import '../css/blogpage.css';

import db from '../content/db';

class ArticlePage extends Component {

    constructor(props) {
        super();

        var obj = {
                title: "",
                text: "",
                img: "",
                author: "",
                date: "",
                video: ""
            }

        this.state = {
            loading: false,
            article: obj
        };

    }

    componentDidMount(){
        this.setState({ loading: true });

        var childName = "Article/" + decodeURIComponent(this.props.match.params.id);

        db.ref().child(childName).on('value',
            snapshot => {
                var obj = snapshot.val();
                this.setState({ loading: false, article: obj });
            }
        )
    }
  
    render() {
        var post = this.state.article;
        var loading = this.state.loading;
        var blogText = post.text.replace(/\\/g,'  \n  ');

        return(
            <>
                {loading ? (
                    <Row className="articleContainer">
                        <Col xs={{span:12}} className="blogContainer blogGap">
                            <Col id="articleTitle" xs={{span:12}} > Loading... </Col>
                        </Col>
                    </Row>
                ):(
                    <Row className="articleContainer">
                        <Col xs={{span:12}} className="blogContainer blogGap">
                            <Col id="articleTitle" xs={{span:12}} > {post.title} </Col>
                        </Col>

                        <Col xs={{span:12}} className="blogContainer articleSubTitle">
                            <Col xs={{span:12}}>By: {post.author}</Col>
                            <Col xs={{span:12}}>Created on {post.date}</Col>
                        </Col>

                        <Col xs={{span:12}} className="blogContainer articleText">
                            <Col xs={{span:12}}>
                                <ReactMarkdown parserOptions={{ commonmark: true }} children={blogText} />
                            </Col>
                        </Col>

                        <Col xs={{span:12}} className="blogContainer">
                            <Col id="articleVideo" xs={{span:10,offset:1}} lg={{span:6,offset:3}}>
                                {post.video ? (
                                    <iframe title="articleVideo" width="100%" height="100%" src={post.video} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                ) : (
                                    <></>
                                )}
                                
                            </Col>
                        </Col>
                    </Row>
                )}
            </>
        );                        
    }

}

export default ArticlePage;
