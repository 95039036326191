import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import '../css/blogpage.css';

import leftTitle from "../assets/team/leftTitle.png";
import rightTitle from "../assets/team/rightTitle.png";

import ImgCon from "../util/imgCon";
import db from '../content/db';

class BlogPage extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: false,
            articles: []
        };

    }

    componentDidMount(){
        this.setState({ loading: true });

        db.ref().child("Article").on('value',
            snapshot => {
                var articles = snapshot.val();
                let BlogData = [];

                for (var article in articles) {
                    if (Object.prototype.hasOwnProperty.call(articles, article)) {
                        var obj = {};
                        console.log()
                        obj.type = "article";
                        obj.title = articles[article].title;
                        obj.text = articles[article].text;
                        obj.date = articles[article].date;
                        obj.url = encodeURIComponent(article);
                        BlogData.unshift(obj);
                    }
                }

                const sortDates = (a, b) => {

                    var aDate = (a.date).split("-");
                    var bDate = (b.date).split("-");

                    console.log(bDate,aDate)

                    return (new Date(bDate[2],bDate[0],bDate[1])).getTime() - (new Date(aDate[2],aDate[0],aDate[1])).getTime();
                }

                const sortedArticles = BlogData.sort(sortDates)

                console.log(sortedArticles)
                
                this.setState({ loading: false, articles: BlogData });
            }
        )
    }
  
    render() {
        var posts = this.state.articles;
        var loading = this.state.loading;

        return(
            <>
                <Row className="blogContainer blogGap">
                    <Col id="blogLeftTitle" xs={{span:3}} lg={{span:2,offset:1}}>
                        <img src={leftTitle} alt="Mirastell"/>
                    </Col>
                    <Col id="blogTitle" xs={{span:6}} lg={{span:6,offset:3}}> Dev Blog </Col>
                    <Col id="blogRightTitle" xs={{span:3}} lg={{span:2,offset:0}}>
                        <img src={rightTitle} alt="Mirastell"/>
                    </Col>
                </Row>
                {(Array.isArray(posts) && posts.length) ? (
                    <Row className="blogContainer">
                        {
                            posts.map(article => (
                                <ImgCon key={new Date().getMilliseconds() + (Math.random() * 69420)} item={article} />
                            ))
                        }
                    </Row>
                ) : (
                    loading ? (<div>Loading ...</div>):(<div>There are no articles ...</div>)
                )}
            </>
        );                        
    }

}

export default BlogPage;
