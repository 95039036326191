import firebase from "firebase/app";
import "firebase/database";

const config = {
    apiKey: "AIzaSyDWuh3EcJ6pr5wVpFuz25s71gyKPr7I74Q",
    authDomain: "mirastelldevblog.firebaseapp.com",
    databaseURL: "https://mirastelldevblog.firebaseio.com",
    projectId: "mirastelldevblog",
    storageBucket: "mirastelldevblog.appspot.com",
    messagingSenderId: "647798170246",
    appId: "1:647798170246:web:67dd1c585e6454a25ee707",
    measurementId: "G-P81BEKY03B"
};

firebase.initializeApp(config);

export default firebase.database();
